<template>
  <div v-if="part === 'company'">
    <div class="kompaniya__part">
      <div class="kompaniya__text">
        <h2>{{ data.title || "О компании" }}:</h2>
        <p class="desc" v-html="desc"></p>
      </div>

      <div class="slider-employers">
        <Carousel personal  :employers="data.addData" />
      </div>
    </div>
  </div>

  <div id="coop" v-else-if="part === 'cooperation'">
    <div class="partners__part">
      <img class="partners__img" src="@/assets/img/about_image2.svg" alt="" />
      <div class="partners__text">
        <h2>{{ data.title }}:</h2>
        <p v-html="desc"></p>
      </div>
    </div>
  </div>

  <div id="certificate" v-else-if="part === 'certificate'">
    <div class="certificate__content section-margin">
      <div class="certificate-img">
        <Carousel certf :employers="data.addData" />
      </div>
      <div class="certificate__content-txt">
        <h3>Сертификаты:</h3>
        <p v-html="desc"></p>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="partners__vac">
      <div class="partners__text1">
        <h2 class="partners__titleVac">{{ data.title }}</h2>
        <p class="partners__tab">
          Менеджер по горячим звонкам в колл-центр
          <span type="button" @click="callCenter = !callCenter"
            >подробнее...</span
          >
        </p>
        <transition name="list">
          <p v-if="callCenter" v-html="desc"></p>
        </transition>
      </div>
      <div class="partners__form">
        <h3>Сотрудничество / Работа</h3>
        <p>
          Напишите нам о своем предложении и мы свяжемся с вами в ближайшее
          время.
        </p>
        <form action="/">
          <input type="text" name="name" placeholder="Имя" />
          <input type="text" name="phone" placeholder="Телефон" />
          <textarea
            name="propose"
            id=""
            cols="30"
            rows="5"
            placeholder="Ваше предложение"
          ></textarea>
          <button>Отправить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/ui/Carousel.vue";

export default {
  props: {
    part: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  components: {
    Carousel,
  },
  computed: {
    desc() {
      return (
        this.data.description &&
        this.data.description
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      );
    },
  },
  // watch: {
  //   '$route.hash': {
  //     immediate: true,
  //     handler(val) {
  //       if(val === '#coop') {
  //         this.$router.push('#coop')
  //       }
  //     }
  //   }
  // }
};
</script>

<style scoped>
h3 {
  font-size: 22px;
  line-height: 26px;
}

img {
  max-width: 100%;
}

.slider-employers {
  width: 100%;
  max-width: 665px;
  margin-bottom: 20px;
}

.slider-certificate {
  width: 100%;
  max-width: 350px;
}

.slider-employers,
.kompaniya__part .kompaniya__text {
  flex: 1 1 50%;
}

.partners__part,
.kompaniya__part {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}
.partners__part .partners__text,
.kompaniya__part .kompaniya__text,
.partners__vac .partners__text1 {
  max-width: 520px;
}
.kompaniya__part .kompaniya__text {
  padding-left: 25px;
  border-left: 1px solid #75b051;
}

.partners__part .partners__text h2 {
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  padding-bottom: 26px;
}
.kompaniya__part .kompaniya__text h2 {
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  padding-bottom: 26px;
}
.kompaniya__part .kompaniya__text p,
.partners__part .partners__text p {
  font-size: 14px;
  line-height: 17px;
}

.section-margin {
  margin-top: 120px;
  margin-bottom: 80px;
}
.certificate__content h3 {
  margin-bottom: 50px;
}
.certificate__content,
.career__content {
  display: flex;
  align-items: center;
}
.certificate__content {
  justify-content: space-around;
}
.career__content {
  justify-content: space-between;
}

.certificate-img,
.certificate__content-txt {
  flex: 1 1 45%;
}

.certificate-img {
  width: 100%;
  max-width: 350px;
}

.certificate__content-txt {
  max-width: 470px;
}

.certificate__content-txt p {
  font-size: 14px;
  line-height: 17px;
}

.desc >>> span {
  font-size: 12px;
}
.partners__vac {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
}

.partners__vac .partners__text1 h2 {
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  padding-bottom: 26px;
}
.partners__vac .partners__text1 .partners__tab {
  font-family: "Exo2";
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.partners__vac .partners__text1 span {
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}
.partners__vac .partners__form {
  max-width: 100%;
  max-height: 390px;
  height: auto;
  margin-right: 70px;
  background: #f4f4f4;
  border-radius: 14px;
  padding: 30px 40px;
}
.partners__vac .partners__form h3 {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 16px;
}
.partners__vac .partners__form p {
  max-width: 235px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding-bottom: 20px;
  margin: 0 auto;
}
.partners__vac .partners__form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.partners__vac .partners__form input,
.partners__vac .partners__form textarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 70px 10px 10px;
  border-radius: 5px;
}
.partners__vac .partners__form input[name="name"] {
  margin-bottom: 14px;
}
.partners__vac .partners__form input[name="phone"] {
  margin-bottom: 14px;
}
.partners__vac .partners__form textarea[name="propose"] {
  resize: none;
  margin-bottom: 14px;
}
.partners__vac .partners__form button {
  max-width: 120px;
  margin: 0 auto;
  background: #75b051;
  border-radius: 5px;
  padding: 10px 20px;
  color: #ffffff;
}
/* .desc >>> p {
  color: red;
  margin: 0;
} */

.list-enter-active {
  animation: show 0.6s linear;
}

.list-leave-active {
  animation: show 0.6s linear reverse;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .kompaniya__img {
    order: 1;
  }
  .kompaniya__text {
    order: 2;
  }
}
@media (max-width: 1024px) {
  .certificate__content-txt {
    padding: 0;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .section-margin {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
</style>
