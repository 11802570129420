<template>
  <section class="about">
    <ul class="breadcrumb">
      <li><router-link to="/">Главная </router-link>/</li>
      <li>О компании</li>
    </ul>
    <section class="kompaniya">
      <div class="container">
        <AboutPart
          part="company"
          :data="company"
        />
      </div>
    </section>
    <section class="title">
      <div class="title__mission">
        <h3>Миссия компании:</h3>
        <h2>сохранить и приумножить здоровье нации!</h2>
      </div>
      <div class="container">
        <div class="title__content">
          <div class="title__content-left">
            <h3>Цель компании:</h3>
            <p class="descr">
              Разработка оздоровительной продукции натурального растительного
              происхождения для поддержания и восстановления здоровья.
            </p>
          </div>
          <div class="title__content-right">
            <h3>Наши ценности:</h3>
            <ol class="descr">
              <li>Честность, справедливость, порядочность</li>
              <li>Ответственность в работе</li>
              <li>Стремление к развитию и постоянное совершенствование</li>
              <li>Прозрачность и этика ведения бизнеса</li>
              <li>
                Наши близкие нами гордятся, так как мы работаем над улучшением
                здоровья людей
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="certificate">
      <div class="container">
        <About-part 
          part="certificate"
          :data="certificate"
        />
      </div>
    </section>
    <section class="career section-margin">
      <div class="container">
        <div class="career__content">
          <div class="career__content-left">
            <div class="supline"></div>
            <div class="offer-block">
              <h3>Карьера:</h3>
              <p class="pre-offer">
                Компания RizaHerb® готова пригласить в команду новых, нацеленных
                на результат сотрудников. Возможно, мы ищем именно вас.
              </p>
              <a href="https://wa.me/7029156930">Отправить резюме</a>
            </div>
          </div>
          <img src="../assets/img/offer-img.png" alt="Timur" />
          <!-- <div class="career__content-right">
          </div> -->
        </div>
      </div>
    </section>
    <!-- <section class="partners">
      <div class="container">
        <AboutPart part="cooperation" :data="cooperation" ref="coop" />
        <AboutPart :data="vacancy" />
      </div>
    </section> -->
  </section>
</template>

<script>
import AboutPart from "@/components/about/AboutPart.vue";

export default {
  components: {
    AboutPart,
  },
  data() {
    return {
      information: [],
      fields: [7, 8, 9, 10],
    };
  },
  computed: {
    company() {
      return { ...this.information[0] };
    },
    cooperation() {
      return { ...this.information[1] };
    },
    vacancy() {
      return { ...this.information[2] };
    },
    certificate() {
      return { ...this.information[3] };
    }
  },
  methods: {
    async getInformationById() {
      for (let key of this.fields) {
        const part = await this.$axios(
          `https://back.rizaherb.kz/index.php?route=feed/rest_api/information&id=${key}`
        );
        const {
          data: { data },
        } = part;
        this.information.push(data);
      }
    },
    isRouteHash() {
      if (this.$route.hash) {
        const $el = document.querySelector("#coop");
        setTimeout(() => {
          $el && window.scrollTo(0, $el.offsetTop - 20);
        }, 200);
      }
    },
  },
  created() {
    this.getInformationById();
  },
  mounted() {
    this.isRouteHash();
  },
};
</script>

<style scoped>
h3 {
  font-size: 22px;
  line-height: 26px;
}
.breadcrumb,
.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 25px;
}

.breadcrumb {
  display: flex;
}
.breadcrumb li {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin-left: 5px;
}
.breadcrumb li a:hover {
  opacity: 45%;
}

.partners,
.kompaniya {
  margin-top: 100px;
}
.partners__part,
.kompaniya__part {
  display: flex;
  justify-content: space-between;
  padding-bottom: 42px;
}
.partners__part .partners__text,
.kompaniya__part .kompaniya__text {
  max-width: 520px;
}
.partners__part .partners__text h2 {
  font-size: 22px;
  line-height: 26px;
  text-align: right;
  padding-bottom: 26px;
}
.kompaniya__part .kompaniya__text h2 {
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  padding-bottom: 26px;
}

.section-margin {
  margin-top: 120px;
  margin-bottom: 80px;
}

.title__mission {
  background-color: #75b051;
  padding: 45px 0;
}

.title__mission h3 {
  text-align: center;
  margin-bottom: 10px;
}

.title__mission h2 {
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.title__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}

.title__content-left .descr,
.title__content-right .descr {
  margin-top: 20px;
}

.title__content-left,
.title__content-right {
  flex: 1 1 50%;
  padding: 25px;
}

.title__content-right {
  border-left: 1px solid #75b051;
}
.title__content-right h3 {
  text-align: center;
}
.title__content-left .descr,
.title__content-right .descr,
.title__content-right ol {
  font-size: 20px;
  line-height: 24px;
}

.career__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.career__content-left,
.career__content-right {
  flex: 1 1 45%;
}
.career__content-left .offer-block {
  max-width: 470px;
}

.career .supline {
  width: 315px;
  border: 1px solid #75b051;
  margin-bottom: 60px;
}

.offer-block .pre-offer {
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
}
.offer-block a {
  display: block;
  width: 100%;
  max-width: 215px;
  margin: 0 auto;
  background-color: #75b051;
  color: #ffffff;
  border-radius: 16px;
  padding: 10px 15px;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  text-decoration: none;
}

/* Old styles, temporary left */
.partners__part .partners__text p {
  text-align: right;
  font-size: 14px;
  line-height: 17px;
}

.partners__vac {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
}
.partners__vac .partners__text1 {
  max-width: 520px;
}
.partners__vac .partners__text1 h2 {
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  padding-bottom: 26px;
}
.partners__vac .partners__text1 p {
  font-size: 14px;
  line-height: 17px;
}
.partners__vac .partners__form {
  max-width: 100%;
  margin-right: 70px;
  background: #f4f4f4;
  border-radius: 14px;
  padding: 30px 40px;
}
.partners__vac .partners__form h3 {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 16px;
}
.partners__vac .partners__form p {
  max-width: 235px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding-bottom: 20px;
}
.partners__vac .partners__form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.partners__vac .partners__form input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 70px 10px 10px;
  border-radius: 5px;
}
.partners__vac .partners__form input[name="name"] {
  margin-bottom: 14px;
}
.partners__vac .partners__form input[name="phone"] {
  margin-bottom: 14px;
}
.partners__vac .partners__form button {
  max-width: 120px;
  margin: 0 auto;
  background: #75b051;
  border-radius: 5px;
  padding: 10px 20px;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .partners,
  .kompaniya {
    display: flex;
  }
  .kompaniya,
  .career__content-left {
    padding: 0;
    margin-top: 20px;
  }
  .career__content-left {
    order: 2;
  }
}

@media (max-width: 768px) {
  .section-margin {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  img {
    width: 100%;
    height: auto;
  }
  .career {
    overflow: hidden;
  }
}
</style>
